<template>
  <PDVListeMainVue />
</template>

<script>
import PDVListeMainVue from '@/views/afridia/points_de_vente/liste/PDVListeMainVue.vue'

export default {
  name: 'MainVue',
  components: { PDVListeMainVue },
  data() {
    return {
      rows: [
        {
          id: 1,
          nom: 'Entreprise 1',
          type: 'Type 1',
          canal: 'Canal 1',
          commercial: 'Commercial 1',
          secteur_activite: 'Secteur d\'activité 1',
          telephone: '0102030405',
          region: 'Région 1',
          zone: 'Zone 1',
          arrondissement: 'Arrondissement 1',
          status: 'status 1',
          action: 'Action 1',
        },
        {
          id: 2,
          nom: 'Entreprise 2',
          type: 'Type 2',
          canal: 'Canal 2',
          commercial: 'Commercial 2',
          secteur_activite: 'Secteur d\'activité 2',
          telephone: '0607080910',
          region: 'Région 2',
          zone: 'Zone 2',
          arrondissement: 'Arrondissement 2',
          status: 'status 2',
          action: 'Action 2',
        },
        {
          id: 3,
          nom: 'Entreprise 3',
          type: 'Type 3',
          canal: 'Canal 3',
          commercial: 'Commercial 3',
          secteur_activite: 'Secteur d\'activité 3',
          telephone: '0203040506',
          region: 'Région 3',
          zone: 'Zone 3',
          arrondissement: 'Arrondissement 3',
          status: 'status 3',
          action: 'Action 3',
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
