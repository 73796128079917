<template>
  <div
    :id="id"
    style="width: 100%; height: 400px;"
  />
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

export default {
  name: 'SimpleColumnChartV',
  // props: [
  //   'chartData',
  //   'categoryName',
  //   'valueYName',
  //   'seriesName',
  // ],
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    valueYName: {
      type: String,
      required: true,
    },
    seriesName: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: 'chartdiv2',
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    chartData: {
      handler(newData) {
        this.chart2.data = newData
      },
      deep: true,
    },
  },
  mounted() {
    am4core.useTheme(am4themes_animated)

    const chart2 = am4core.create(this.id, am4charts.XYChart)
    chart2.data = this.chartData

    const categoryAxis = chart2.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = this.categoryName
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.minGridDistance = 30

    const valueAxis = chart2.yAxes.push(new am4charts.ValueAxis())

    const series = chart2.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = this.valueYName
    series.dataFields.categoryX = this.categoryName
    series.name = this.seriesName
    series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]'
    series.columns.template.fillOpacity = 0.8

    const columnTemplate = series.columns.template
    columnTemplate.strokeWidth = 2
    columnTemplate.strokeOpacity = 1

    chart2.cursor = new am4charts.XYCursor()
    chart2.cursor.behavior = 'panXY'
    chart2.cursor.xAxis = categoryAxis
    chart2.cursor.snapToSeries = series
    chart2.exporting.menu = new am4core.ExportMenu()

    this.chart2 = chart2
    // Legend
    chart2.legend = new am4charts.Legend()
  },
  beforeDestroy() {
    if (this.chart2) {
      this.chart2.dispose()
    }
  },
}
</script>

<style scoped>
/* CSS styles go here */
</style>
